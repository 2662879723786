import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingMain from '../components/HeadingMain'
import SEO from '../components/SEO'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  margin-bottom: 90px;
`

const Gallery = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
`

const GalleryImage = styled(GatsbyImage)`
  height: 100%;
  box-shadow: 0 12px 38px ${p => p.theme.lightGray};
  /* FOR NON-GRID-SUPPORT */
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  @supports (display: grid) {
    width: auto;
    margin-bottom: 0;
  }
`

class Presse extends Component {
  render() {
    const {
      galleryImage1,
      galleryImage2,
      galleryImage4,
      galleryImage5,
      galleryImage6,
      galleryImage7,
      galleryImage8,
      galleryImage9,
      galleryImage10,
      galleryImage12,
    } = this.props.data
    return (
      <React.Fragment>
        <SEO title="Presse" />
        <HeadingMain
          mainHeading="Presse"
          subHeading="Hier geben wir Ihnen gerne einen kleinen Einblick in unser Familienweingut in Gols"
        />
        <Container>
          <Gallery>
            <a href="/presse/weingut-goeschl-presse-1.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage1.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-2.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage2.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-3.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage4.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-4.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage12.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-5.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage10.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-6.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage9.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-7.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage8.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-8.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage7.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-9.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage5.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
            <a href="/presse/weingut-goeschl-presse-10.jpg" target="_blank" rel="noopener noreferrer">
              <GalleryImage
                critical={true}
                image={galleryImage6.childImageSharp.gatsbyImageData}
                alt="Weingut Göschl"
              />
            </a>
          </Gallery>
        </Container>
      </React.Fragment>
    )
  }
}

export default Presse

export const query = graphql`
  {
    galleryImage1: file(relativePath: { eq: "photos/weingut-goeschl-familie.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage2: file(relativePath: { eq: "photos/weingut-goeschl-kontakt.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage4: file(relativePath: { eq: "photos/weingut-goeschl-weingut-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage5: file(relativePath: { eq: "photos/weingut-goeschl-verkostungsraum-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage6: file(relativePath: { eq: "photos/weingut-goeschl-verkostungsraum-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage7: file(relativePath: { eq: "photos/weingut-goeschl-keller-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage8: file(relativePath: { eq: "photos/weingut-goeschl-keller-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage9: file(relativePath: { eq: "photos/weingut-goeschl-weine-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage10: file(relativePath: { eq: "photos/weingut-goeschl-famile-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    galleryImage12: file(relativePath: { eq: "photos/weingut-goeschl-weine-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`
